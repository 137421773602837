import React, { Fragment } from 'react';

import clsx from 'clsx';

import PerfectScrollbar from 'react-perfect-scrollbar';
import { Hidden, Drawer, Paper } from '@material-ui/core';


import { connect } from 'react-redux';

import SidebarHeader from '../../layout-components/SidebarHeader';
import SidebarMenu from '../../layout-components/SidebarMenu';


import navItems from './navItems';
import BarChartIcon from '@material-ui/icons/BarChart';
import CalendarTodayIcon from '@material-ui/icons/CalendarToday';
import ChatIcon from '@material-ui/icons/ChatOutlined';
import CodeIcon from '@material-ui/icons/Code';
import DashboardIcon from '@material-ui/icons/DashboardOutlined';
import ErrorIcon from '@material-ui/icons/ErrorOutline';
import FolderIcon from '@material-ui/icons/FolderOutlined';
import DashboardTwoToneIcon from '@material-ui/icons/DashboardTwoTone';
import GradeTwoTone from '@material-ui/icons/GradeTwoTone';
import ListAltIcon from '@material-ui/icons/ListAlt';
import LockOpenIcon from '@material-ui/icons/LockOpenOutlined';
import MailIcon from '@material-ui/icons/MailOutlined';
import PresentToAllIcon from '@material-ui/icons/PresentToAll';
import PeopleIcon from '@material-ui/icons/PeopleOutlined';
import PersonIcon from '@material-ui/icons/PersonOutlined';
import ReceiptIcon from '@material-ui/icons/ReceiptOutlined';
import SettingsIcon from '@material-ui/icons/SettingsOutlined';
import ViewModuleIcon from '@material-ui/icons/ViewModule';
import TableChartIcon from '@material-ui/icons/TableChart';
import EventAvailableIcon from '@material-ui/icons/EventAvailable';
import TrendingUpIcon from '@material-ui/icons/TrendingUp';
import SpeedIcon from '@material-ui/icons/Speed';
import HomeIcon from '@material-ui/icons/Home';
import Divider from '@material-ui/core/Divider';
import { List, ListItem, ListItemIcon, ListItemText }  
    from '@material-ui/core';
import { Link } from 'react-router-dom'; 
import {
  setSidebarToggleMobile,
  setSidebarHover,
  setSidebarToggle,
  setSidebarFooter 
} from '../../reducers/ThemeOptions';
const { localStorage } = global.window;
let $dataUser = localStorage.getItem('user');
const Sidebar = props => {
  const {
    setSidebarToggleMobile,
    sidebarToggleMobile,
    sidebarFixed,

    sidebarHover,
    setSidebarHover,
    sidebarToggle,  
    sidebarShadow  
  } = props;

  const toggleHoverOn = () => setSidebarHover(true);
  const toggleHoverOff = () => setSidebarHover(false);

  const closeDrawer = () => setSidebarToggleMobile(!sidebarToggleMobile);
  const handlerUser = (user) => {
    let ruta = '';
    if (user) {
      return ruta = "/inicio";
    } else {
      return ruta = "/PagesRegister";
    }
  }
  const sidebarMenuContent = (
    <div
      className={clsx({
        'app-sidebar-nav-close': sidebarToggle && !sidebarHover
      })}>
      
      {navItems.map(list => (         
        <SidebarMenu
          component="div"
          key={list.label}
          pages={list.content}
          title={list.content.label} 
        /> 
      

      ))}
     
    </div>
  );

  return (
    <Fragment>
      <Hidden lgUp>
        <Drawer
          anchor="left"
          open={sidebarToggleMobile}
          onClose={closeDrawer}
          variant="temporary"
          elevation={4}
          className="app-sidebar-wrapper-lg">
          <SidebarHeader />
          <PerfectScrollbar>
           
          {/*  {sidebarMenuContent}   */}
          <Link to='/inicio'>
            <List  color="primary" > 
              <ListItem button key='Menú de navegación'> 
               
                <ListItemText  primary='MENÚ DE NAVEGACIÓN'  style={ { color: "#26305A" , font:"normal normal bold 21px/21px Roboto", fontSize:"bold"}} /> 
              </ListItem> 
            </List> 
          </Link>
          <Divider />
          <Link to='/inicio'>
            <List  color="primary" > 
              <ListItem button key='Estado general' style={ { borderRadius: "30px"}}> 
                <ListItemIcon className="app-sidebar-icon"><HomeIcon  color="primary" /> 
                </ListItemIcon> 
                <ListItemText  primary='Inicio'  style={ { color: "#26305A" , font:"normal normal bold 21px/21px Roboto"}} /> 
              </ListItem> 
            </List> 
          </Link> 
          
          <Link to='/Tabs' > 
          <List  color="primary"> 
            <ListItem button key='Historico' style={ { borderRadius: "30px"}}> 
              <ListItemIcon><TableChartIcon  color="primary"  /> 
              </ListItemIcon> 
              <ListItemText primary='Historico' style={ { color: "#26305A", font:"normal normal bold 21px/21px Roboto"}}  /> 
            </ListItem> 
            </List> 
          </Link> 
          <Link to='/ApplicationsFileManager' > 
          <List  color="primary"> 
            <ListItem button key='Tabla de Eventos' style={ { borderRadius: "30px"}}> 
              <ListItemIcon><EventAvailableIcon  color="primary"  /> 
              </ListItemIcon> 
              <ListItemText primary='Tabla de Eventos' style={ { color: "#26305A", font:"normal normal bold 21px/21px Roboto"}}  /> 
            </ListItem> 
            </List> 
          </Link> 
          <Link to='/Curvas' > 
          <List  color="primary"> 
            <ListItem button key='Curvas' style={ { borderRadius: "30px"}}> 
              <ListItemIcon><TrendingUpIcon  color="primary"  /> 
              </ListItemIcon> 
              <ListItemText primary='Curvas' style={ { color: "#26305A", font:"normal normal bold 21px/21px Roboto"}}  /> 
            </ListItem> 
            </List> 
          </Link> 
          <Link to='/Oee' > 
          <List  color="primary"> 
            <ListItem button key='OEE' style={ { borderRadius: "30px"}}> 
              <ListItemIcon><SpeedIcon  color="primary"  /> 
              </ListItemIcon> 
              <ListItemText primary='OEE' style={ { color: "#26305A", font:"normal normal bold 21px/21px Roboto"}}  /> 
            </ListItem> 
            </List> 
          </Link> 
          <Link to={`${handlerUser($dataUser)}`} > 
          <List  color="primary"> 
            <ListItem button key='Configuración' style={ { borderRadius: "30px"}}> 
              <ListItemIcon><SettingsIcon  color="primary"  /> 
              </ListItemIcon> 
              <ListItemText primary='Configuración' style={ { color: "#26305A", font:"normal normal bold 21px/21px Roboto"}}  /> 
            </ListItem> 
            </List> 
          </Link> 
          </PerfectScrollbar>
        </Drawer>
      </Hidden>

      <Hidden mdDown>
        <Paper
          onMouseEnter={toggleHoverOn}
          onMouseLeave={toggleHoverOff}
          className={clsx('app-sidebar-wrapper', {
            'app-sidebar-wrapper-close': sidebarToggle,
            'app-sidebar-wrapper-open': sidebarHover,
            'app-sidebar-wrapper-fixed': sidebarFixed
          })}
          square
          open={sidebarToggle}
          elevation={sidebarShadow ? 11 : 3}>
          <SidebarHeader />
          <div
            className={clsx({
              'app-sidebar-menu': sidebarFixed,
              'app-sidebar-collapsed': sidebarToggle && !sidebarHover
            })}>
            <PerfectScrollbar options={{ wheelPropagation: false }}>
           {/* {sidebarMenuContent}   */}
           <Link to='/inicio'>
            <List  color="primary" > 
              <ListItem button key='Menú de navegación'>                
                <ListItemText  primary='MENÚ DE NAVEGACIÓN'  style={ { color: "#26305A" , font:"normal normal bold 21px/21px Roboto" , fontSize:"bold"}} /> 
              </ListItem> 
            </List> 
          </Link>
          <Divider />
           <Link to='/inicio'> 
            <List  color="primary" > 
              <ListItem button key='Estado general' style={ { borderRadius: "30px"}}> 
                <ListItemIcon className="app-sidebar-icon"><HomeIcon  color="primary" /> 
                </ListItemIcon> 
                <ListItemText  primary='Inicio'  style={ { color: "#26305A" , font:"normal normal bold 21px/21px Roboto"}} /> 
              </ListItem> 
            </List> 
          </Link> 
         
          <Link to='/Tabs' > 
          <List  color="primary"> 
            <ListItem button key='Historico' style={ { borderRadius: "30px"}}> 
              <ListItemIcon><TableChartIcon  color="primary"  /> 
              </ListItemIcon> 
              <ListItemText primary='Historico' style={ { color: "#26305A", font:"normal normal bold 21px/21px Roboto"}}  /> 
            </ListItem> 
            </List> 
          </Link> 
          <Link to='/ApplicationsFileManager' > 
          <List  color="primary"> 
            <ListItem button key='Tabla de Eventos' style={ { borderRadius: "30px"}}> 
              <ListItemIcon><EventAvailableIcon  color="primary"  /> 
              </ListItemIcon> 
              <ListItemText primary='Tabla de Eventos' style={ { color: "#26305A", font:"normal normal bold 21px/21px Roboto"}}  /> 
            </ListItem> 
            </List> 
          </Link> 
          <Link to='/Curvas' > 
          <List  color="primary"> 
            <ListItem button key='Curvas' style={ { borderRadius: "30px"}}> 
              <ListItemIcon><TrendingUpIcon  color="primary"  /> 
              </ListItemIcon> 
              <ListItemText primary='Curvas' style={ { color: "#26305A", font:"normal normal bold 21px/21px Roboto"}}  /> 
            </ListItem> 
            </List> 
          </Link> 
          <Link to='/Oee' > 
          <List  color="primary"> 
            <ListItem button key='OEE' style={ { borderRadius: "30px"}}> 
              <ListItemIcon><SpeedIcon  color="primary"  /> 
              </ListItemIcon> 
              <ListItemText primary='OEE' style={ { color: "#26305A", font:"normal normal bold 21px/21px Roboto"}}  /> 
            </ListItem> 
            </List> 
          </Link> 
          <Link to={`${handlerUser($dataUser)}`} > 
          <List  color="primary"> 
            <ListItem button key='Configuración' style={ { borderRadius: "30px"}}> 
              <ListItemIcon><SettingsIcon  color="primary"  /> 
              </ListItemIcon> 
              <ListItemText primary='Configuración' style={ { color: "#26305A", font:"normal normal bold 21px/21px Roboto"}}  /> 
            </ListItem> 
            </List> 
          </Link>
             
            </PerfectScrollbar>
          </div>
        </Paper>
      </Hidden>
    </Fragment>
  );
};

const mapStateToProps = state => ({
  sidebarFixed: state.ThemeOptions.sidebarFixed,
  headerFixed: state.ThemeOptions.headerFixed,
  sidebarToggle: state.ThemeOptions.sidebarToggle,
  sidebarHover: state.ThemeOptions.sidebarHover,
  sidebarShadow: state.ThemeOptions.sidebarShadow,
  sidebarFooter: state.ThemeOptions.sidebarFooter, 
  sidebarToggleMobile: state.ThemeOptions.sidebarToggleMobile
});

const mapDispatchToProps = dispatch => ({
  setSidebarToggleMobile: enable => dispatch(setSidebarToggleMobile(enable)),
  setSidebarToggle: enable => dispatch(setSidebarToggle(enable)),
  setSidebarHover: enable => dispatch(setSidebarHover(enable)),
  setSidebarFooter: enable => dispatch(setSidebarFooter(enable)) 
});

export default connect(mapStateToProps, mapDispatchToProps)(Sidebar);

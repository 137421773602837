import React, { Fragment, useState } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import {
  Grid,
  Container,
  Input,
  InputLabel,
  InputAdornment,
  FormControlLabel,
  IconButton,
  Box,
  Typography,
  Checkbox,
  Tabs,
  Tab,
  Card,
  CardContent,
  Button,
  Tooltip,

  FormControl
} from '@material-ui/core';

import MailOutlineTwoToneIcon from '@material-ui/icons/MailOutlineTwoTone';
import LockTwoToneIcon from '@material-ui/icons/LockTwoTone';

import hero1 from '../../../assets/images/hero-bg/hero-1.jpg';
import { withStyles } from '@material-ui/core/styles';
import {UserRol} from '../../../config/config';
const {localStorage} = global.window;
const keyUser = localStorage.getItem('user'); 
if (keyUser !== ''){
  localStorage.removeItem('user');
}
const StyledTabs = withStyles({
  indicator: {
    display: 'flex',
    justifyContent: 'center',
    backgroundColor: 'transparent',
    height: '6px',
    '& > div': {
      maxWidth: 40,
      height: '4px',
      borderRadius: '25px',
      width: '100%',
      backgroundColor: '#000'
    }
  }
})(props => <Tabs {...props} TabIndicatorProps={{ children: <div /> }} />);

const StyledTab = withStyles(theme => ({
  root: {
    textTransform: 'none',
    color: theme.palette.primary[900],
    fontWeight: theme.typography.fontWeightRegular,
    fontSize: theme.typography.pxToRem(15),
    marginRight: theme.spacing(1),
    '&:focus': {
      opacity: 1
    }
  }
}))(props => <Tab disableRipple {...props} />);

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      {...other}>
      {value === index && <Box p={0}>{children}</Box>}
    </Typography>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired
};

const LivePreviewExample = () => {
  const [value, setValue] = React.useState(0);
  const [valRouter, setValRouter] = React.useState(1);
  const [loading, setLoading] = useState(false);
  const username = useFormInput('');
  const password = useFormInput('');

  const [error, setError] = useState(null);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const [checked1, setChecked1] = React.useState(true);

  const handleChange1 = event => {
    setChecked1(event.target.checked);
  };

  const handleLogin =  () => {
    setError(null);
    setLoading(true);
    const URI= UserRol();
    axios.post(URI, { username: username.value, password: password.value })
      .then(response => {
       console.log(response.data);
       if (response.data !== 'Sin resultados'){ 
        console.log(response.data.data[0].rol);
        if (response.data.data[0].rol === '2') {                
          setValRouter("/ApplicationsProjects");
          localStorage.setItem('user', username.value); 
       
        } 
        if (response.data.data[0].rol === '1') {         
          localStorage.setItem('user',username.value);
          setValRouter("/inicio");
        }
    } else{
        window.alert('Usuario o Contraseña incorrecta');
      } 
      })
  }

  return (
    <Fragment>
      <div className="app-wrapper min-vh-100 bg-white">
        <div className="app-main min-vh-100">
          <div className="app-content p-0">
            <div className="app-inner-content-layout--main">
              <div className="flex-grow-1 w-100 d-flex align-items-center">
                <div className="bg-composed-wrapper--content">
                  <Grid container spacing={0} className="min-vh-100">
                    <Grid
                      item
                      xs={12}
                      md={4}
                      lg={5}
                      className="d-flex align-items-center">
                      <div className="hero-wrapper w-100 bg-composed-wrapper bg-plum-plate min-vh-100">
                        <div className="flex-grow-1 w-100 d-flex align-items-center">
                          <div
                            className="bg-composed-wrapper--image"
                            style={{ backgroundImage: 'url(' + hero1 + ')' }}
                          />
                          <div className="bg-composed-wrapper--bg bg-premium-dark opacity-5" />
                          <div className="bg-composed-wrapper--content p-5">
                            <div className="d-flex align-items-center">

                              <Tooltip
                                arrow
                                title="Create your own register or login pages using the included elements."
                                placement="right">
                                <span className="text-white-50 pl-3">
                                  <FontAwesomeIcon
                                    icon={['far', 'question-circle']}
                                  />
                                </span>
                              </Tooltip>
                            </div>

                          </div>
                        </div>
                        <div className="hero-footer pb-4">
                          <Tooltip arrow title="Facebook">
                            <IconButton
                              color="inherit"
                              size="medium"
                              variant="outlined"
                              className="text-white-50">
                              <FontAwesomeIcon
                                icon={['fab', 'facebook']}
                                className="font-size-md"
                              />
                            </IconButton>
                          </Tooltip>
                          <Tooltip arrow title="Twitter">
                            <IconButton
                              color="inherit"
                              size="medium"
                              variant="outlined"
                              className="text-white-50">
                              <FontAwesomeIcon
                                icon={['fab', 'twitter']}
                                className="font-size-md"
                              />
                            </IconButton>
                          </Tooltip>
                          <Tooltip arrow title="Google">
                            <IconButton
                              color="inherit"
                              size="medium"
                              variant="outlined"
                              className="text-white-50">
                              <FontAwesomeIcon
                                icon={['fab', 'google']}
                                className="font-size-md"
                              />
                            </IconButton>
                          </Tooltip>
                          <Tooltip arrow title="Instagram">
                            <IconButton
                              color="inherit"
                              size="medium"
                              variant="outlined"
                              className="text-white-50">
                              <FontAwesomeIcon
                                icon={['fab', 'instagram']}
                                className="font-size-md"
                              />
                            </IconButton>
                          </Tooltip>
                        </div>
                      </div>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      md={8}
                      lg={7}
                      className="d-flex align-items-center">
                      <Container maxWidth="sm">
                        <div className="pt-5 pb-4">
                          <StyledTabs
                            value={value}
                            indicatorColor="primary"
                            textColor="primary"
                            onChange={handleChange}>
                            <StyledTab label="Inicio de sección" />
                          </StyledTabs>
                        </div>
                        <TabPanel value={value} index={0}>
                          <p className="font-size-lg mb-5 text-black-50">
                            Complete los campos a continuación para entrar al módulo de administración .
                          </p>
                          <Card className="mx-0 bg-secondary mt-0 w-100 p-0 mb-4 border-0">
                            <CardContent className="p-3">
                              <form className="px-5">
                                <div className="mb-3">
                                  <FormControl className="w-100">
                                    <InputLabel htmlFor="input-with-icon-adornment">
                                      Nombre de usuario
                                    </InputLabel>
                                    <Input
                                      fullWidth
                                      id="input-with-icon-adornment"
                                      startAdornment={
                                        <InputAdornment position="start">
                                          <MailOutlineTwoToneIcon />
                                        </InputAdornment>
                                      }
                                      {...username}
                                      autoComplete="new-password"
                                    />
                                  </FormControl>
                                </div>
                                <div className="mb-3">
                                  <FormControl className="w-100">
                                    <InputLabel htmlFor="standard-adornment-password">
                                      Contraseña
                                    </InputLabel>
                                    <Input
                                      id="standard-adornment-password"
                                      fullWidth
                                      type="password"
                                      startAdornment={
                                        <InputAdornment position="start">
                                          <LockTwoToneIcon />
                                        </InputAdornment>
                                      }
                                      {...password}
                                      autoComplete="new-password"
                                    />
                                  </FormControl>
                                </div>
                                <div className="w-100">
                                  <FormControlLabel
                                    control={
                                      <Checkbox
                                        checked={checked1}
                                        onChange={handleChange1}
                                        value="checked1"
                                        color="primary"
                                      />
                                    }
                                    label="Recuérdame"
                                  />
                                </div>
                                <div className="text-center">
                               
                                  <Button
                                    size="large"
                                    className="text-white"
                                    variant="contained"
                                    color="primary"
                                    onClick={handleLogin}

                                  >
                                     <Link to={valRouter} >
                                    <span className="btn-wrapper--icon">
                                      <FontAwesomeIcon
                                        icon={['fas', 'arrow-right']}

                                        value={loading ? 'Loading...' : 'Login'} disabled={loading}
                                      />
                                     Entrar
                                    </span>
                                    </Link>
                                  </Button>
                                 
                                </div>
                              </form>
                            </CardContent>
                          </Card>
                        </TabPanel>
                      </Container>
                    </Grid>
                  </Grid>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

const useFormInput = initialValue => {
  const [value, setValue] = useState(initialValue);

  const handleChange = e => {
    setValue(e.target.value);
  }
  return {
    value,
    onChange: handleChange
  }
}

export default LivePreviewExample;

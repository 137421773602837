import React, { lazy, Suspense, Fragment } from 'react';
import { Switch, Route, Redirect, useLocation } from 'react-router-dom';
import { AnimatePresence, motion } from 'framer-motion';

import { ThemeProvider } from '@material-ui/styles';

import { ClimbingBoxLoader } from 'react-spinners';

import MuiTheme from './theme';

// Layout Blueprints

import {
  LeftSidebar, 
  MinimalLayout,
  PresentationLayout
} from './layout-blueprints';

// Pages
import PagesRegister from './pages/PagesRegister';

const ApexCharts = lazy(() => import('./pages/inicio'));
const ChartjsCharts = lazy(() => import('./pages/Oee'));
const Tabs = lazy(() => import('./pages/Historicos'));
const Curvas = lazy(() => import('./pages/Curvas'));
const ApplicationsProjects = lazy(() =>
  import('./pages/Configuracion')
);
const ApplicationsFileManager = lazy(() =>
  import('./pages/Alarmas')
);




const Routes = () => {
  const location = useLocation();

  const pageVariants = {
    initial: {
      opacity: 0,
      scale: 0.99
    },
    in: {
      opacity: 1,
      scale: 1
    },
    out: {
      opacity: 0,
      scale: 1.01
    }
  };

  const pageTransition = {
    type: 'tween',
    ease: 'anticipate',
    duration: 0.4
  };

  const SuspenseLoading = () => {
    return (
     <Fragment>
        <div className="d-flex align-items-center flex-column vh-100 justify-content-center text-center py-3">
          <div className="d-flex align-items-center flex-column px-4"  >
            <ClimbingBoxLoader color={'#5383ff'} loading={true} />
          </div>
          <div className="text-muted font-size-xl text-center pt-3">
          Espere mientras se carga CPI CLOUD STUDIO
           
          </div>
        </div>
      </Fragment>
    );
    
  };
  return (
    <ThemeProvider theme={MuiTheme}>
      <AnimatePresence>
        <Suspense fallback={<SuspenseLoading />}>
         <Switch>
            <Redirect exact from="/" to="/inicio" />
            <Route path={['/inicio']}>
              <PresentationLayout>
                <LeftSidebar>
                <Switch location={location} key={location.pathname}>
                  <motion.div
                    initial="initial"
                    animate="in"
                    exit="out"
                    variants={pageVariants}
                    transition={pageTransition}>
                    <Route path="/inicio" component={ApexCharts} />
                  </motion.div>
                </Switch>
                </LeftSidebar>
              </PresentationLayout>
            </Route>
            <Route
              path={[                
                '/PagesRegister'               
              ]}>
              <MinimalLayout>
                <Switch location={location} key={location.pathname}>
                  <motion.div
                    initial="initial"
                    animate="in"
                    exit="out"
                    variants={pageVariants}
                    transition={pageTransition}>
                  
                    <Route path="/PagesRegister" component={PagesRegister} />
                    
                  </motion.div>
                </Switch>
              </MinimalLayout>
            </Route>
            <Route
              path={[                
                '/inicio',               
                '/Oee',  
                '/ApplicationsProjects',
                '/Tabs',
                '/Curvas',
                '/ApplicationsFileManager'                
              ]}>
              <LeftSidebar>
                <Switch location={location} key={location.pathname}>
                  <motion.div
                    initial="initial"
                    animate="in"
                    exit="out"
                    variants={pageVariants}
                    transition={pageTransition}>
                    
                    <Route path="/inicio" component={ApexCharts} />
                   
                    <Route path="/Oee" component={ChartjsCharts} />
                    <Route path="/Tabs" component={Tabs} />
                   
                     <Route
                      path="/ApplicationsProjects"
                      component={ApplicationsProjects}
                    />
                     <Route
                      path="/Curvas"
                      component={Curvas}
                    />
                     <Route
                      path="/ApplicationsFileManager"
                      component={ApplicationsFileManager}
                    />  
                  </motion.div>
                </Switch>
              </LeftSidebar>
            </Route>
          </Switch>
        </Suspense>
      </AnimatePresence>
    </ThemeProvider>
  );
};

export default Routes;




const base_URI = () => {
    let REACT_APP_URI = "https://cpi-cloud-studio.herokuapp.com/api/";
    return REACT_APP_URI;
}
const analyze_URI = () => {
    let REACT_APP_URI_VARREF = "https://cpi-cloud-studio.herokuapp.com/api/analyze"
    return REACT_APP_URI_VARREF;
}

const Oee_URI = () => {
    let REACT_APP_URI_OEE = "https://cpi-cloud-studio.herokuapp.com/api/sdaca"
    return REACT_APP_URI_OEE
}
const OeeALL_URI = () => {
    let REACT_APP_URI_OEEALL = "https://cpi-cloud-studio.herokuapp.com/api/sdaca2"
    return REACT_APP_URI_OEEALL
}
const maquina_URI = () => {
    let REACT_APP_URI_MAQ = "https://cpi-cloud-studio.herokuapp.com/api/maquina"
    return REACT_APP_URI_MAQ;
}
const chart_Oee_URI = (lng) => {
    let REACT_APP_URI_CHART_OEE = "https://cpi-cloud-studio.herokuapp.com/api/oeeChart";
    return REACT_APP_URI_CHART_OEE;
}
const last_Events_URI = (lng) => {
    let REACT_APP_last_Events_URI = "https://cpi-cloud-studio.herokuapp.com/api/eventos";
    return REACT_APP_last_Events_URI;
}

const last_Values_URI = (lng) => {
    let REACT_APP_last_Values_URI = "https://cpi-cloud-studio.herokuapp.com/api/lastV";
    return REACT_APP_last_Values_URI;
}

const acumulado = (lng) => {
    let REACT_APP_ACUMULADO = "https://cpi-cloud-studio.herokuapp.com/api/acumulado";
    return REACT_APP_ACUMULADO;
}
const medidos = (lng) => {
    let REACT_APP_MEDIDOS = "https://cpi-cloud-studio.herokuapp.com/api/valoreMed";
    return REACT_APP_MEDIDOS;
}
const OeeLast = (lng) => {
    let REACT_APP_OEE_LAST = "https://cpi-cloud-studio.herokuapp.com/api/valoreOeeLast";
    return REACT_APP_OEE_LAST;
}
const CalAcum = (lng) => {
    let REACT_APP_CAL_ACUM = "https://cpi-cloud-studio.herokuapp.com/api/calAcum";
    return REACT_APP_CAL_ACUM;
}
const SendVaref = (lng) => {
    let REACT_APP_SEND_VAR= "https://cpi-cloud-studio.herokuapp.com/api/sendVaref";
    return REACT_APP_SEND_VAR;
}

const UserRol = (lng) => {
    let REACT_APP_ROL = "https://cpi-cloud-studio.herokuapp.com/api/users";
    return REACT_APP_ROL;
}

const handleSidebar = (draw) =>{
    let RECT_DRAW = true;
    if (draw === true){
    return RECT_DRAW = true
    }else{
    return RECT_DRAW = false    
    }
 
}

module.exports = {
    base_URI,
    analyze_URI,
    Oee_URI,
    OeeALL_URI,
    maquina_URI,
    chart_Oee_URI,
    last_Events_URI,
    last_Values_URI,
    handleSidebar,
    acumulado,
    medidos,
    CalAcum,
    OeeLast,
    SendVaref,
    UserRol
}
import React, { Fragment, useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import {
  Grid,  
  Hidden, 
  Paper,
  Box,
  Typography,
  Dialog, 
  Tabs,
  Tab,
  Card,
  CardContent,
  Button,
  Tooltip 
} from '@material-ui/core';

import EditIcon from '@material-ui/icons/Edit';

import DashboardTwoToneIcon from '@material-ui/icons/DashboardTwoTone';

import { connect } from 'react-redux';

import hero1 from '../../assets/images/hero-bg/hero-1.jpg';

import SaveIcon from '@material-ui/icons/Save';
import PrintIcon from '@material-ui/icons/Print';


import Breadcrumbs from '@material-ui/core/Breadcrumbs';

import SpeedDial from '@material-ui/lab/SpeedDial';
import SpeedDialIcon from '@material-ui/lab/SpeedDialIcon';
import SpeedDialAction from '@material-ui/lab/SpeedDialAction';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      {...other}>
      {value === index && <Box p={4}>{children}</Box>}
    </Typography>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired
};
const actions = [ 
  { icon: <SaveIcon />, name: 'Guardar' },
  { icon: <PrintIcon />, name: 'Imprimir' }
 
];

function PageTitle(props) {
  const {
    pageTitleStyle,
    pageTitleBackground,
    pageTitleShadow,
    pageTitleBreadcrumb,
    pageTitleIconBox,
    pageTitleDescription
  } = props;

  const [modal1, setModal1] = useState(false);
  const toggle1 = () => setModal1(!modal1);

  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  /* const [checked1, setChecked1] = React.useState(true);

  const handleChange1 = event => {
    setChecked1(event.target.checked);
  };
 */
  const [open, setOpen] = React.useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Fragment>
      <Paper
        square
        elevation={pageTitleShadow ? 6 : 2}
        className={clsx('app-page-title', pageTitleStyle, pageTitleBackground)}>
        <div>
          {pageTitleBreadcrumb && (
            <Breadcrumbs
              separator={<NavigateNextIcon fontSize="small" />}
              className="mb-4"
              maxItems={2}
              aria-label="breadcrumb">
              <Link color="inherit" to="#" onClick={e => e.preventDefault()}>
                Home
              </Link>
              <Link color="inherit" to="#" onClick={e => e.preventDefault()}>
                Dashboards
              </Link>
              <Link color="inherit" to="#" onClick={e => e.preventDefault()}>
                Examples
              </Link>
              <Link color="inherit" to="#" onClick={e => e.preventDefault()}>
                Pages
              </Link>
              <Typography color="textPrimary">{props.titleHeading}</Typography>
            </Breadcrumbs>
          )}

          <Box className="app-page-title--first">
            {pageTitleIconBox && (
              <Paper
                elevation={2}
                className="app-page-title--iconbox d-70 d-flex align-items-center bg-secondary justify-content-center">
                <DashboardTwoToneIcon />
              </Paper>
            )}
            <div className="app-page-title--heading">
              <h1>{props.titleHeading}</h1>
              {pageTitleDescription && (
                <div className="app-page-title--description">
                  {props.titleDescription}
                </div>
              )}
            </div>
          </Box>
        </div>

        {!open && (
          <div className="d-flex align-items-center">
            <Button onClick={toggle1} variant="contained" color="secondary">
              <span className="d-none d-xl-block">Open dialog</span>
              <span className="btn-wrapper--icon d-block d-xl-none">
                <FontAwesomeIcon icon={['far', 'object-group']} />
              </span>
            </Button>
            <Dialog scroll="body" maxWidth="lg" open={modal1} onClose={toggle1}>
              <Grid container spacing={0}>
                <Grid item xs={12} lg={5}>
                  <div className="hero-wrapper bg-composed-wrapper bg-plum-plate h-100">
                    <div className="flex-grow-1 w-100 d-flex align-items-center">
                      <div
                        className="bg-composed-wrapper--image "
                        style={{ backgroundImage: 'url(' + hero1 + ')' }}
                      />
                      <div className="bg-composed-wrapper--bg bg-second opacity-5" />
                      <div className="bg-composed-wrapper--content p-5">
                        <div className="d-flex align-items-center">
                          <span className="px-4 h-auto py-1 badge badge-second badge-pill">
                          Opciones de filtros
                          </span>
                          <Tooltip
                            arrow
                            placement="right"
                            title="Opciones de filtros">
                            <span className="text-white-50 pl-3">
                              <FontAwesomeIcon
                                icon={['far', 'question-circle']}
                              />
                            </span>
                          </Tooltip>
                        </div>
                        <div className="text-white mt-3">
                          <h1 className="display-4 my-3 font-weight-bold">
                          Seleccionar filtros para estado general
                          </h1>
                         
                          <div className="divider border-2 my-5 border-light opacity-2 rounded w-25" />
                         
                        </div>
                      </div>
                    </div>
                   
                  </div>
                </Grid>
                <Grid item xs={12} lg={7}>
                  <div className="bg-white ">
                    <Tabs
                      value={value}
                      indicatorColor="primary"
                      textColor="primary"
                      variant="fullWidth"
                      onChange={handleChange}>
                      <Tab className="py-3" label="Últimos valores" />
                      <Tab className="py-3" label="Acumulados" />
                      <Tab className="py-3" label="OEE" />
                    </Tabs>
                    <TabPanel value={value} index={0}>
                      <Grid container spacing={4}>
                        <Grid item md={6} lg={4}>
                          <Card className="card-box text-black-50 bg-secondary mb-5 p-3">
                           
                          </Card>
                        </Grid>
                        <Grid item md={6} lg={4}>
                          <Card className="card-box text-black-50 bg-secondary mb-5 p-3">
                           
                          </Card>
                        </Grid>
                        <Grid item md={6} lg={4}>
                          <Card className="card-box text-black-50 bg-secondary mb-5 p-3">
                           
                          </Card>
                        </Grid>
                      </Grid>
                      <Card className="card-box bg-secondary mb-5">
                        <CardContent className="p-3">
                         
                        </CardContent>
                      </Card>
                      <div className="text-center">
                        <Button variant="outlined" color="primary">
                         Aplicar filtros(UV)
                        </Button>
                      </div>
                    </TabPanel>
                    <TabPanel value={value} index={1}>
                    <Grid container spacing={4}>
                        <Grid item md={6} lg={4}>
                          <Card className="card-box text-black-50 bg-secondary mb-5 p-3">
                           
                          </Card>
                        </Grid>
                        <Grid item md={6} lg={4}>
                          <Card className="card-box text-black-50 bg-secondary mb-5 p-3">
                           
                          </Card>
                        </Grid>
                        <Grid item md={6} lg={4}>
                          <Card className="card-box text-black-50 bg-secondary mb-5 p-3">
                           
                          </Card>
                        </Grid>
                      </Grid>
                      <Card className="card-box bg-secondary mb-5">
                        <CardContent className="p-3">
                         
                        </CardContent>
                      </Card>
                      <div className="text-center">
                        <Button variant="outlined" color="primary">
                         Aplicar filtros(AC)
                        </Button>
                      </div>
                    </TabPanel>
                    <TabPanel value={value} index={2}>
                    <Grid container spacing={4}>
                        <Grid item md={6} lg={4}>
                          <Card className="card-box text-black-50 bg-secondary mb-5 p-3">
                           
                          </Card>
                        </Grid>
                        <Grid item md={6} lg={4}>
                          <Card className="card-box text-black-50 bg-secondary mb-5 p-3">
                           
                          </Card>
                        </Grid>
                        <Grid item md={6} lg={4}>
                          <Card className="card-box text-black-50 bg-secondary mb-5 p-3">
                           
                          </Card>
                        </Grid>
                      </Grid>
                      <Card className="card-box bg-secondary mb-5">
                        <CardContent className="p-3">
                         
                        </CardContent>
                      </Card>
                      <div className="text-center">
                        <Button variant="outlined" color="primary">
                         Aplicar filtros(OEE)
                        </Button>
                      </div>
                    </TabPanel>
                  </div>
                </Grid>
              </Grid>
            </Dialog>
          </div>
        )}
        <Hidden smDown>
          <div className="speedial-wrapper">
            <SpeedDial
              ariaLabel="SpeedDial menu"
              icon={<SpeedDialIcon openIcon={<EditIcon />} />}
              onClose={handleClose}
              onOpen={handleOpen}
              direction="left"
              open={open}>
              {actions.map(action => (
                <SpeedDialAction
                  key={action.name}
                  icon={action.icon}
                  tooltipTitle={action.name}
                  onClick={handleClose}
                />
              ))}
            </SpeedDial>
          </div>
        </Hidden>
      </Paper>
    </Fragment>
  );
}
const mapStateToProps = state => ({
  pageTitleStyle: state.ThemeOptions.pageTitleStyle,
  pageTitleBackground: state.ThemeOptions.pageTitleBackground,
  pageTitleShadow: state.ThemeOptions.pageTitleShadow,
  pageTitleBreadcrumb: state.ThemeOptions.pageTitleBreadcrumb,
  pageTitleIconBox: state.ThemeOptions.pageTitleIconBox,
  pageTitleDescription: state.ThemeOptions.pageTitleDescription
});

export default connect(mapStateToProps)(PageTitle);

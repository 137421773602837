import React, { Fragment } from 'react';

import clsx from 'clsx';
/* import { Link } from 'react-router-dom'; */
import projectLogo from '../../assets/images/unnamed.svg';
import { Link } from 'react-router-dom';

import { IconButton, Box } from '@material-ui/core';


import { connect } from 'react-redux';



const HeaderLogo = props => {
  const { sidebarToggle, sidebarHover } = props;
  return (
    <Fragment>
      <div
        className={clsx('app-header-logo', {
          'app-header-logo-close': sidebarToggle,
          'app-header-logo-open': sidebarHover
        })}>
        <Box
          className="header-logo-wrapper"
          title="CPI CLUD STUDIO">
           
               <label >CPI</label> 
           
          <Box className="header-logo-text" >
         
             <label style={{fontSize:'36px'}}>KUMO</label> 
            </Box>
        </Box>
      </div>
    </Fragment>
  );
};

const mapStateToProps = state => ({
  sidebarToggle: state.ThemeOptions.sidebarToggle,
  sidebarHover: state.ThemeOptions.sidebarHover
});

export default connect(mapStateToProps)(HeaderLogo);

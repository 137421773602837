import BarChartIcon from '@material-ui/icons/BarChart';
import CalendarTodayIcon from '@material-ui/icons/CalendarToday';
import ChatIcon from '@material-ui/icons/ChatOutlined';
import CodeIcon from '@material-ui/icons/Code';
import DashboardIcon from '@material-ui/icons/DashboardOutlined';
import ErrorIcon from '@material-ui/icons/ErrorOutline';
import FolderIcon from '@material-ui/icons/FolderOutlined';
import DashboardTwoToneIcon from '@material-ui/icons/DashboardTwoTone';
import GradeTwoTone from '@material-ui/icons/GradeTwoTone';
import ListAltIcon from '@material-ui/icons/ListAlt';
import LockOpenIcon from '@material-ui/icons/LockOpenOutlined';
import MailIcon from '@material-ui/icons/MailOutlined';
import PresentToAllIcon from '@material-ui/icons/PresentToAll';
import PeopleIcon from '@material-ui/icons/PeopleOutlined';
import PersonIcon from '@material-ui/icons/PersonOutlined';
import ReceiptIcon from '@material-ui/icons/ReceiptOutlined';
import SettingsIcon from '@material-ui/icons/SettingsOutlined';
import ViewModuleIcon from '@material-ui/icons/ViewModule';
import TableChartIcon from '@material-ui/icons/TableChart';
import EventAvailableIcon from '@material-ui/icons/EventAvailable';
import TrendingUpIcon from '@material-ui/icons/TrendingUp';
import SpeedIcon from '@material-ui/icons/Speed';
import HomeIcon from '@material-ui/icons/Home';
const { localStorage } = global.window;
let $dataUser = localStorage.getItem('user');
var iconsMap = {
  BarChartIcon: BarChartIcon,
  CalendarTodayIcon: CalendarTodayIcon,
  ChatIcon: ChatIcon,
  CodeIcon: CodeIcon,
  DashboardIcon: DashboardIcon,
  ErrorIcon: ErrorIcon,
  FolderIcon: FolderIcon,
  DashboardTwoToneIcon: DashboardTwoToneIcon,
  GradeTwoTone: GradeTwoTone,
  ListAltIcon: ListAltIcon,
  LockOpenIcon: LockOpenIcon,
  MailIcon: MailIcon,
  PresentToAllIcon: PresentToAllIcon,
  PeopleIcon: PeopleIcon,
  PersonIcon: PersonIcon,
  ReceiptIcon: ReceiptIcon,
  SettingsIcon: SettingsIcon,
  ViewModuleIcon: ViewModuleIcon,
  TableChartIcon: TableChartIcon,
  EventAvailableIcon: EventAvailableIcon,
  TrendingUpIcon: TrendingUpIcon,
  SpeedIcon: SpeedIcon,
  HomeIcon: HomeIcon

};

const handlerUser = (user) => {
  let ruta = '';
  if (user) {
    return ruta = "/inicio";
  } else {
    return ruta = "/PagesRegister";
  }
}
   


export default [
  {
    label: 'Menú de navegación',
    content: JSON.parse(
      `[
        {
         "label": "Estado general", 
          "icon": "HomeIcon",
          "prueba":"/inicio",
          "content": [
            {                
              "to": "/inicio"
            }
          ]
        },
        {
          "label": "Historico",
          "icon": "TableChartIcon",  
          "prueba": "/Tabs", 
         
          "content": [
            {
                 
              "to": "/Tabs"
            }
            
          ]
        },
        {
          "label": "Tabla de Eventos",  
          "icon": "EventAvailableIcon", 
          "prueba": "/ApplicationsFileManager",  
          "content": [
            {                
              "to": "/ApplicationsFileManager"
            }     
          ]
        },
        {
          "label": "Curvas",
          "icon": "TrendingUpIcon",
          "prueba": "/Curvas",
          "content": [
            {
                "to": "/Curvas"
            }
          ]
        },
         {
          "label": "Scada Omron",  
          "icon": "SpeedIcon",   
          "prueba": "/Oee",
          "content": [
            {                 
              "to": "/Oee"
            }
          ]
        },
        {
          "label": "Administración",
          "icon": "SettingsIcon",
          "prueba": "${handlerUser($dataUser)}",    
          "content": [
            {
              "to": "${handlerUser($dataUser)}"
            }
          ]
        }  
      ]`,
      (key, value) => {
        if (key === 'icon') {
          return iconsMap[value];
        } else {
          return value;
        }
      }
    )
  }
];

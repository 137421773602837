import React, { Fragment } from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {
  Grid,
  Hidden,
  Drawer,
  IconButton,
  Box,
  Button,
  Tooltip,
  Divider
} from '@material-ui/core';



import MenuOpenRoundedIcon from '@material-ui/icons/MenuOpenRounded';

import ListIcon from '@material-ui/icons/List';

import Chart from 'react-apexcharts';

function HeaderDrawer() {
  const chart36Options = {
    chart: {
      toolbar: {
        show: false
      },
      sparkline: {
        enabled: true
      }
    },

    stroke: {
      color: '#1bc943',
      curve: 'smooth',
      width: 4
    },
    colors: ['#1bc943'],
    fill: {
      color: '1bc943',
      gradient: {
        shade: 'light',
        type: 'vertical',
        shadeIntensity: 0.7,
        inverseColors: true,
        opacityFrom: 1,
        opacityTo: 0,
        stops: [0, 100]
      }
    },
    xaxis: {
      crosshairs: {
        width: 1
      }
    },
    yaxis: {
      min: 0
    }
  };
  const chart36Data = [
    {
      name: 'Monthly Analytics',
      data: [47, 38, 56, 24, 45, 54, 38, 56, 24, 65]
    }
  ];

  const [open, setOpen] = React.useState(false);

  function handleDrawerClose() {
    setOpen(true);
  }

  function handleDrawerOpen() {
    setOpen(false);
  }

  return (
    <Fragment>
      <Hidden smDown>
        <Box className="ml-2">
          <Tooltip title="Ver consumo de Energía" placement="right">
            <IconButton
              color="inherit"
              onClick={handleDrawerClose}
              size="medium"
              className="btn-inverse">
              {open ? <MenuOpenRoundedIcon /> : <ListIcon />}
            </IconButton>
          </Tooltip>
        </Box>
        <Drawer
          anchor="right"
          open={open}
          variant="temporary"
          elevation={4}
          onClose={handleDrawerOpen}>
          <Box className="app-header-drawer">
            <PerfectScrollbar>
              <div className="p-4">
                <div className="text-center">
                  <div className="font-weight-bold font-size-lg mb-0 text-black">
                  Consumo de energía
                  </div>
                  
                </div>
                
              </div>
              <Divider />
              <div className="p-4 text-center">                
                <Chart
                  options={chart36Options}
                  series={chart36Data}
                  type="area"
                  height={120}
                />
                <Grid container spacing={4} className="mt-3">
                  <Grid item xs={6}>
                    <span className="opacity-6 pb-2">Mes anterior(Kwh Total)</span>
                    <div className="d-flex align-items-center justify-content-center">
                      <span className="font-weight-bold font-size-lg">
                        <small className="opacity-6 pr-1"></small>
                        70,3
                      </span>
                      
                    </div>
                  </Grid>
                  <Grid item xs={6}>
                    <span className="opacity-6 pb-2">Mes actual(Kwh Total)</span>
                    <div className="d-flex align-items-center justify-content-center">
                      <span className="font-weight-bold font-size-lg">
                        <small className="opacity-6 pr-1"></small>
                        51,5
                      </span>
                      
                    </div>
                  </Grid>
                </Grid>
                <div className="pb-2 pt-4 text-center">
                  <Button size="small" variant="outlined" color="secondary">
                    <span className="btn-wrapper--label">
                      Ver reporte Completo
                    </span>
                    <span className="btn-wrapper--icon">
                      <FontAwesomeIcon icon={['fas', 'arrow-right']} />
                    </span>
                  </Button>
                </div>
              </div>
              <Divider />
              
            </PerfectScrollbar>
          </Box>
        </Drawer>
      </Hidden>
    </Fragment>
  );
}

export default HeaderDrawer;
